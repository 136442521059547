import { defineStore } from 'pinia';
import {
  JOBTYPE_PICKUP,
  PROCESS_LOAD_ADDITIONAL_TRACKING_DATA,
  PROCESS_LOAD_TRACKING_DATA,
  PROCESS_LOAD_TRIGGER_TRACKING_URL,
  TRACKING_DELIVERY_STATE_CLOSE,
  TRACKING_DELIVERY_STATE_FAR,
  TRACKING_DELIVERY_STATE_LAST,
  TRACKING_DELIVERY_STATE_NEAR,
  TRACKING_DELIVERY_STATE_UNCLEAR,
  TRACKING_STATE_BLOCKED,
  TRACKING_STATE_DELIVERED,
  TRACKING_STATE_ETA_VALID_DELTA,
  TRACKING_STATE_NOT_DELIVERED,
  TRACKING_STATE_NOT_PICKED_UP,
  TRACKING_STATE_OUT_FOR_DELIVERY,
  TRACKING_STATE_PICKED_UP,
  TRACKING_STATE_SHADOWED,
  TRACKING_STATE_THRESHOLD_BLURRY,
  TRACKING_STATE_THRESHOLD_MED,
  TRACKING_STATE_THRESHOLD_MIN,
  TRACKING_STATE_UNKNOWN
} from '@/store/constants.js';
import { configGetter } from '@/config/configGetter.js';
import { useAlertStore } from '@/stores/alert.js';
import { fetchWithBaseParams } from '@/util/fetch.js';
import { useLoadingProcesses } from '@/stores/loadingProcesses.js';
import { useWebsocketStore } from '@/stores/websocket.js';
import { useCustomizedDeliveryStore } from '@/stores/customizedDelivery.js';
import { sendAnalytics } from '@/services/analytics.js';
import moment from 'moment-timezone';

const getEtaTimestampDelta = timestamp => {
  if (!timestamp) {
    return 0;
  }
  const now = Date.now();
  return new Date(timestamp).getTime() - now;
};

export const useTrackingDataStore = defineStore('trackingData', {
  state: () => ({
    isPrivate: false,
    data: {
      supportedCustomizedDeliveryTypes: [],
      status: {
        remainingStops: 0
      },
      maxStopsToShowDriverLocation: 10
    }
  }),
  getters: {
    isServiceTypePickup(state) {
      return state.data.jobType === JOBTYPE_PICKUP;
    },
    getTrackingState() {
      const tracking = { ...this.data.status };
      const TRACKING_STATE_THRESHOLD_MED =
        this.data.maxStopsToShowDriverLocation;
      const trackingType = tracking.type;
      const stops =
        typeof tracking.remainingStops === 'number'
          ? tracking.remainingStops
          : undefined;
      const delivered = trackingType === TRACKING_STATE_DELIVERED;
      const outForDelivery = trackingType === TRACKING_STATE_OUT_FOR_DELIVERY;
      const notDelivered = trackingType === TRACKING_STATE_NOT_DELIVERED;
      const unknown = trackingType === TRACKING_STATE_UNKNOWN;
      const blocked = trackingType === TRACKING_STATE_BLOCKED;
      const shadowed = this.data.tourMode === TRACKING_STATE_SHADOWED;
      const negativeStopsExceeded =
        Math.sign(stops) === -1 && stops < TRACKING_STATE_THRESHOLD_BLURRY;
      const moreThan15MinAway =
        getEtaTimestampDelta(tracking.etaTimestamp) >
        -TRACKING_STATE_ETA_VALID_DELTA;

      if (this.isServiceTypePickup) {
        if (delivered) {
          return TRACKING_STATE_PICKED_UP;
        }

        if (notDelivered) {
          return TRACKING_STATE_NOT_PICKED_UP;
        }
      }

      if (notDelivered) {
        return TRACKING_STATE_NOT_DELIVERED;
      }

      if (delivered) {
        return TRACKING_STATE_DELIVERED;
      }

      if (blocked) {
        sendAnalytics(this.data, 'tracking_state', 'blocked', {
          reason: 'blocked status from backend'
        });
        return TRACKING_STATE_BLOCKED;
      }

      if (outForDelivery) {
        if (shadowed) {
          if (
            getEtaTimestampDelta(tracking.etaTimestampMax) <
            TRACKING_STATE_ETA_VALID_DELTA
          ) {
            sendAnalytics(this.data, 'tracking_state', 'unknown', {
              reason:
                'etaTimestampMax more than 15mins in the past (shadowed tour)'
            });
            return TRACKING_STATE_UNKNOWN;
          }
          return TRACKING_STATE_SHADOWED;
        }

        if (
          getEtaTimestampDelta(tracking.etaTimestamp) <
          TRACKING_STATE_ETA_VALID_DELTA
        ) {
          sendAnalytics(this.data, 'tracking_state', 'unknown', {
            reason: 'etaTimestamp more than 15mins in the past'
          });
          return TRACKING_STATE_UNKNOWN;
        }

        if (
          stops < this.data.trackingStateThresholdMax &&
          stops > TRACKING_STATE_THRESHOLD_MED
        ) {
          return TRACKING_DELIVERY_STATE_NEAR;
        }
        if (
          stops <= TRACKING_STATE_THRESHOLD_MED &&
          (stops > TRACKING_STATE_THRESHOLD_MIN || moreThan15MinAway)
        ) {
          return TRACKING_DELIVERY_STATE_CLOSE;
        }
        if (
          stops <= TRACKING_STATE_THRESHOLD_MIN &&
          stops >= TRACKING_STATE_THRESHOLD_BLURRY
        ) {
          return TRACKING_DELIVERY_STATE_LAST;
        }
        if (stops >= this.data.trackingStateThresholdMax) {
          return TRACKING_DELIVERY_STATE_FAR;
        }
        if (negativeStopsExceeded) {
          return TRACKING_DELIVERY_STATE_UNCLEAR;
        }
        if (tracking.etaTimestamp === null) {
          return TRACKING_DELIVERY_STATE_UNCLEAR;
        }
      }

      if (unknown) {
        sendAnalytics(this.data, 'tracking_state', 'unknown', {
          reason: 'unknown status from backend'
        });
        return TRACKING_STATE_UNKNOWN;
      }

      return TRACKING_DELIVERY_STATE_UNCLEAR;
    },

    getTimeframe(state) {
      const { etaTimestampMin: from, etaTimestampMax: to } =
        state.data?.status || {};
      if (!from || !to) return false;

      return { from, to };
    },

    getDeliveryStatusDetail(state) {
      return state.data.status?.detail || null;
    },

    showPositionExplanation(state) {
      return (
        this.getTrackingState === TRACKING_DELIVERY_STATE_NEAR &&
        state.data.toAddress
      );
    },

    getMinutesLeft(state) {
      let response = {
        from: null,
        to: null,
        format: null
      };

      const { etaTimestampMin, etaTimestampMax } = state.data?.status || {};
      if (!etaTimestampMin || !etaTimestampMax) return response;

      const from = moment(etaTimestampMin);
      const to = moment(etaTimestampMax);

      const now = moment();
      const durationStart = moment.duration(from.diff(now));
      const durationEnd = moment.duration(to.diff(now));

      if (durationStart.asMinutes() > 0 && durationStart.asMinutes() <= 60) {
        response = {
          from: Math.ceil(durationStart.asMinutes()),
          to: Math.ceil(durationEnd.asMinutes()),
          format: 'min'
        };
      }
      if (durationStart.asMinutes() > 60) {
        response = {
          from: moment.utc(durationStart.asMilliseconds()).format('h:mm'),
          to: moment.utc(durationEnd.asMilliseconds()).format('h:mm'),
          format: 'h'
        };
      }
      return response;
    }
  },
  actions: {
    updateTrackingData(payload, isPrivate) {
      if (isPrivate) {
        this.data = {
          ...payload,
          updateTimestamp: new Date().getTime()
        };
      } else {
        this.data = {
          ...payload,
          // 'maxStopsToShowDriverLocation' is only part of the private parcel response
          maxStopsToShowDriverLocation: TRACKING_STATE_THRESHOLD_MED,
          updateTimestamp: new Date().getTime()
        };
      }

      if (payload.customizedDelivery) {
        const customizedDeliveryStore = useCustomizedDeliveryStore();
        customizedDeliveryStore.setCustomizedDelivery(
          payload.customizedDelivery
        );
      }

      this.isPrivate = isPrivate;
    },

    async loadTrackingDataById(payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}`;

      const alertStore = useAlertStore();
      const loadingProcessesStore = useLoadingProcesses();
      const webSocketStore = useWebsocketStore();
      try {
        alertStore.setAlert({}, null);
        loadingProcessesStore.incrementProcesses(PROCESS_LOAD_TRACKING_DATA);

        const response = await fetchWithBaseParams(url, 'GET', payload);

        this.updateTrackingData(response, false);
        webSocketStore.setIsUpdateAvailable(false);
        webSocketStore.initializeUpdates(false);
      } catch (e) {
        const msg = e.body ? { ...e.body } : e.message;
        alertStore.setAlert(msg, 'error', false);
        webSocketStore.setSubscriptionActive(false);
      } finally {
        loadingProcessesStore.decrementProcesses(PROCESS_LOAD_TRACKING_DATA);
      }
    },

    async loadAdditionalTrackingData(payload) {
      const url = `${configGetter().API_URL}/tenant/{tenant}/parcel/{trackingId}/{postcode}`;

      const alertStore = useAlertStore();
      const loadingProcessesStore = useLoadingProcesses();
      const webSocketStore = useWebsocketStore();

      try {
        alertStore.setAlert({}, null);
        loadingProcessesStore.incrementProcesses(
          PROCESS_LOAD_ADDITIONAL_TRACKING_DATA
        );

        const response = await fetchWithBaseParams(url, 'GET', payload);

        this.updateTrackingData(response, true);

        webSocketStore.setIsUpdateAvailable(false);
        webSocketStore.initializeUpdates(true);
      } catch (e) {
        const msg = e.body ? { ...e.body } : e.message;

        alertStore.setAlert(msg, 'error', false);
        webSocketStore.setSubscriptionActive(false);
      } finally {
        loadingProcessesStore.decrementProcesses(
          PROCESS_LOAD_ADDITIONAL_TRACKING_DATA
        );
      }
    },
    async triggerTrackingUrl(payload) {
      const url = payload.url;

      const alertStore = useAlertStore();
      const loadingProcessesStore = useLoadingProcesses();

      try {
        alertStore.setAlert({}, null);
        loadingProcessesStore.incrementProcesses(
          PROCESS_LOAD_TRIGGER_TRACKING_URL
        );

        await fetchWithBaseParams(url, 'GET', payload);
      } catch (e) {
        const msg = e.body ? { ...e.body } : e.message;

        alertStore.setAlert(msg, 'error', false);
      } finally {
        loadingProcessesStore.decrementProcesses(
          PROCESS_LOAD_TRIGGER_TRACKING_URL
        );
      }
    }
  }
});
